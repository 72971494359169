import "../Claims.scss";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RKTextField from "../../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomButton from "../../../../../Component/Common/CustomButton/CustomButton";
import TextArea from "../../../../../Component/Common/FieldTypes/TextArea/TextArea";
import { ClaimUserData } from "../../../../../Type/Claims/ClaimUserData";
// import DatePicker from "../../../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import { ChangeEvent } from "react";
import {
  EVDateInput as DatePicker
 } from "@evervent_pvt_ltd/ui-kit";
 import "@evervent_pvt_ltd/ui-kit/dist/index.css";
 

const HealthClaim = ({
  inputFields,
  setInputFields,
  fieldsUpdateState,
  selectFile,
  fileChange,
  copyOfPolicyDocCash,
  copyOfHealthCardCash,
  copyOfRejectionLetterCash,
  copyOfCashReceiptCash,
  copyOfInvestigationReportCash,
  copyOfPharmacyBillCash,
  copyOfCaseSheetCash,
  copyOfPolicyDocRe,
  copyOfHealthCardRe,
  copyOfRejectionLetterRe,
  copyOfCashReceiptRe,
  copyOfInvestigationReportRe,
  copyOfPharmacyBillRe,
  copyOfCaseSheetRe,
  copyOfPolicyDocClaim,
  copyOfHealthCardClaim,
  copyOfCashReceiptClaim,
  copyOfInvestigationReportClaim,
  copyOfPharmacyBillClaim,
  copyOfCaseSheetClaim,
  handleFileChange,
  copyOfRejectionLetterPartially
}: {
  inputFields: ClaimUserData;
  setInputFields: Function;
  fieldsUpdateState: Function;
  selectFile: Function;
  fileChange: Function;
  copyOfPolicyDocCash: any;
  copyOfHealthCardCash: any;
  copyOfRejectionLetterCash: any;
  copyOfCashReceiptCash: any;
  copyOfInvestigationReportCash: any;
  copyOfPharmacyBillCash: any;
  copyOfCaseSheetCash: any;
  copyOfPolicyDocRe: any;
  copyOfHealthCardRe: any;
  copyOfRejectionLetterRe: any;
  copyOfCashReceiptRe: any;
  copyOfInvestigationReportRe: any;
  copyOfPharmacyBillRe: any;
  copyOfCaseSheetRe: any;
  copyOfPolicyDocClaim: any;
  copyOfHealthCardClaim: any;
  copyOfCashReceiptClaim: any;
  copyOfInvestigationReportClaim: any;
  copyOfPharmacyBillClaim: any;
  copyOfCaseSheetClaim: any;
  handleFileChange: any;
  copyOfRejectionLetterPartially: any
}) => {
  // const handleFileChange = (
  //   refName: string,
  //   event: ChangeEvent<HTMLInputElement>
  // ) => {
  //   const file = event.target.files?.[0]; // Access the file object from the event if it exists

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       const base64 = reader.result as string; // The base64 representation of the file
  //       const fileInfo: { name: string; url: string } = {
  //         name: file.name,
  //         url: base64.split("base64,")[1],
  //       };

  //       fileChange(refName, fileInfo);
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  console.log("inputFields.HealthIns", inputFields.HealthIns)


  return (
    <>
      {/* Health Insurance */}
      <Grid xs={12} className="gridHeading">
        Claim Type
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} padding="0px">
        <TabContext value={inputFields.HealthIns.claimType}>
          <Box className="mb-4">
            <TabList
              className="tabListButtons"
              onChange={(
                e,
                a: "Cashless" | "Reimbursement" | "Claim Partially Settled"
              ) => {
                setInputFields({
                  ...inputFields,
                  HealthIns: {
                    ...inputFields.HealthIns,
                    claimType: a,
                  },
                });
              }}>
              <Tab
                className={
                  inputFields.HealthIns.claimType === "Cashless"
                    ? "active"
                    : "inActive"
                }
                label="Cashless"
                value="Cashless"
              />
              <Tab
                className={
                  inputFields.HealthIns.claimType === "Reimbursement"
                    ? "active"
                    : "inActive"
                }
                label="Reimbursement"
                value="Reimbursement"
              />
              <Tab
                className={
                  inputFields.HealthIns.claimType === "Claim Partially Settled"
                    ? "active"
                    : "inActive"
                }
                label="Claim Partially Settled"
                value="Claim Partially Settled"
              />
            </TabList>
          </Box>
          {/* cashless */}
          <TabPanel
            className="tabPanel"
            value="Cashless"
            sx={{ display: "flex", gap: "24px", flexDirection: "column" }}>
            <Grid container spacing={3}>
              <Grid xs={12} sm={6}>
                <h5 className="healthHeading mb-3">Claim Status</h5>
                <TabList
                  className="tabListButtons"
                  onChange={(e, a: "Pending" | "Rejected") => {
                    setInputFields({
                      ...inputFields,
                      HealthIns: {
                        ...inputFields.HealthIns,
                        cashLess: {
                          ...inputFields.HealthIns.cashLess,
                          claimStatus: a,
                        },
                      },
                    });
                  }}>
                  <Tab
                    className={
                      inputFields.HealthIns.cashLess.claimStatus ===
                      "Pending"
                        ? "active"
                        : "inActive"
                    }
                    label="Pending"
                    value="Pending"
                  />
                  <Tab
                    className={
                      inputFields.HealthIns.cashLess.claimStatus ===
                      "Rejected"
                        ? "active"
                        : "inActive"
                    }
                    label="Rejected"
                    value="Rejected"
                  />
                </TabList>
              </Grid>
              <Grid xs={12} sm={6} className="healthHeading">
                <h5 className="healthHeading mb-3"> Customer Type</h5>
                <div className="bimaType">
                  <CustomButton
                    text_name={"Bimastreet"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.cashLess.customerType ===
                      "Bimastreet"
                        ? "yes"
                        : "nope"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          cashLess: {
                            ...inputFields.HealthIns.cashLess,
                            customerType: "Bimastreet",
                          },
                        },
                      });
                    }}
                  />
                  <CustomButton
                    text_name={"Other"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.cashLess.customerType ===
                      "Bimastreet"
                        ? "nope"
                        : "yes"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          cashLess: {
                            ...inputFields.HealthIns.cashLess,
                            customerType: "Other",
                          },
                        },
                      });
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
              <Grid xs={6}>
                <CustomButton
                  text_name={"Rejected"}
                  class_name="greenMdBtn yes"
                />
              </Grid>
              <Grid xs={6}>
                <div className="bimaType">
                  <CustomButton
                    text_name={"Bimastreet"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.cashLess.customerType ===
                      "Bimastreet"
                        ? "yes"
                        : "nope"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          cashLess: {
                            ...inputFields.HealthIns.cashLess,
                            customerType: "Bimastreet",
                          },
                        },
                      });
                    }}
                  />
                  <CustomButton
                    text_name={"Other"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.cashLess.customerType ===
                      "Bimastreet"
                        ? "nope"
                        : "yes"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          cashLess: {
                            ...inputFields.HealthIns.cashLess,
                            customerType: "Other",
                          },
                        },
                      });
                    }}
                  />
                </div>
              </Grid>
            </Grid> */}
            <Grid container spacing={3}>
              <Grid xs={12} sm={6}>
                <>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid xs={12}>
                      <div className="healthPolicyDoc">
                        <h5>Policy Document</h5>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid 
                     xs={12}
                     sm={12}
                     md={6}
                     lg={6}
                     sx={{ display: "flex", alignItems: "center" }}
                    >
                      <div className="healthPolicyDoc">
                        <Box className="fileUpload fileUpload1">
                          <input
                            type="file"
                            ref={copyOfPolicyDocCash}
                            onChange={(e) =>
                              handleFileChange("copyOfPolicyDoc")
                            }
                            style={{ display: "none" }}
                            
                          />
                          <Button
                            className="cloudButton"
                            variant="contained"
                            onClick={() => selectFile("copyOfPolicyDoc")}>
                            <CloudUploadIcon />
                            {inputFields.HealthIns.cashLess.copyOfPolicyDoc
                              .docFile?.name
                              ? inputFields.HealthIns.cashLess.copyOfPolicyDoc
                                  .docFile?.name
                              : "Browse to upload policy doc"}
                          </Button>
                          {inputFields.HealthIns.cashLess.copyOfPolicyDoc.warning ? <span className="error">Select Policy Document</span> : ""}
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </>
              </Grid>
              <Grid xs={12} sm={6}>
                <>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid xs={12}>
                      <div className="healthPolicyDoc">
                        <h5>Health Card</h5>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid 
                     xs={12}
                     sm={12}
                     md={6}
                     lg={6}
                     sx={{ display: "flex", alignItems: "center" }}
                    >
                      <div className="healthPolicyDoc">
                        <Box className="fileUpload fileUpload1">
                          <input
                            type="file"
                            ref={copyOfHealthCardCash}
                            onChange={(e) =>
                              handleFileChange("copyOfHealthCard")
                            }
                            style={{ display: "none" }}
                            
                          />
                          <Button
                            className="cloudButton"
                            variant="contained"
                            onClick={() => selectFile("copyOfHealthCard")}>
                            <CloudUploadIcon />
                            {inputFields.HealthIns.cashLess.copyOfHealthCard
                              .docFile?.name
                              ? inputFields.HealthIns.cashLess.copyOfHealthCard
                                  .docFile?.name
                              : "Browse to upload Health Card"}
                          </Button>
                          {inputFields.HealthIns.cashLess.copyOfHealthCard.warning ? <span className="error">Select Health Card</span> : ""}

                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <DatePicker
                  className="px-0"
                  title={"Date of admission"}
                  value={inputFields.HealthIns.cashLess.dateOfAdmission.value}
                  attrName={["HealthIns", "cashLess", "dateOfAdmission"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.cashLess.dateOfAdmission.warning
                  }
                  error_message={" Enter Date of Admission"}
                  date_validation_type="DAYS"
                  min_date={60}
                  max_date={0}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <DatePicker
                  className="px-0"
                  title={"Date of discharge"}
                  value={inputFields.HealthIns.cashLess.dateOfDiscahrge.value}
                  attrName={["HealthIns", "cashLess", "dateOfDiscahrge"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.cashLess.dateOfDiscahrge.warning
                  }
                  error_message={" Enter Date Of Discharge"}
                  date_validation_type="DAYS"
                  min_date={60}
                  max_date={0}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
             {inputFields.HealthIns.cashLess.claimStatus ===
              "Rejected" ? <>
              <Grid xs={12} sm={12} md={6} lg={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Reason for rejection"}
                  value={
                    inputFields.HealthIns.cashLess.reasonForRejection.value
                  }
                  attrName={["HealthIns", "cashLess", "reasonForRejection"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.cashLess.reasonForRejection.warning
                  }
                  error_message={" Enter Reason For Rejection"}
                />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{ display: "flex", alignItems: "center" }}>
                <Box className="fileUpload file1" padding="0px">
                  <input
                    type="file"
                    ref={copyOfRejectionLetterCash}
                    onChange={(e) => handleFileChange("copyOfRejectionLetter")}
                    style={{ display: "none" }}
                    
                  />
                  <Button
                    className="cloudButton"
                    variant="contained"
                    onClick={() => selectFile("copyOfRejectionLetter")}>
                    <CloudUploadIcon />
                    {inputFields.HealthIns.cashLess.copyOfRejectionLetter
                      .docFile?.name
                      ? inputFields.HealthIns.cashLess.copyOfRejectionLetter
                          .docFile?.name
                      : "Browse to upload rejection letter"}
                  </Button>
                  {inputFields.HealthIns.cashLess.copyOfRejectionLetter.warning ? <span className="error">Select Rejection Letter</span> : ""}
                </Box>
              </Grid>
              </> :  <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Reason for Pending"}
                    value={
                      inputFields.HealthIns.cashLess.reasonForPending.value
                    }
                    attrName={[
                      "HealthIns",
                      "cashLess",
                      "reasonForPending",
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={
                      inputFields.HealthIns.cashLess.reasonForPending
                        .warning
                    }
                    error_message={" Enter Reason For Pending"}
                  />
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={
                    "Discharge Summary Original (With date - time of admission and discharge)"
                  }
                  value={inputFields.HealthIns.cashLess.dischargeSummary.value}
                  attrName={["HealthIns", "cashLess", "dischargeSummary"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.cashLess.dischargeSummary.warning
                  }
                  error_message=" Enter Discharge Summary"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"Final Bill Breakup"}
                  value={inputFields.HealthIns.cashLess.finalBillBreakup.value}
                  attrName={["HealthIns", "cashLess", "finalBillBreakup"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.cashLess.finalBillBreakup.warning
                  }
                  error_message=" Enter Final Bill Breakup"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} lg={12}>
                <div className="claimPolicyDoc">
                  <h5>Cash Paid Receipt: </h5>
                  <Box className="fileUpload file1" padding="0px">
                    <input
                      type="file"
                      ref={copyOfCashReceiptCash}
                      onChange={(e) => handleFileChange("copyOfCashReceipt")}
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfCashReceipt")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.cashLess.copyOfCashReceipt.docFile
                        ?.name
                        ? inputFields.HealthIns.cashLess.copyOfCashReceipt
                            .docFile?.name
                        : "Browse to upload cash paid receipt"}
                    </Button>
                    {inputFields.HealthIns.cashLess.copyOfCashReceipt.warning ? <span className="error">Select Cash Paid Receipt</span> : ""}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid 
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ display: "flex", alignItems: "center" }}
              >
                <div className="healthPolicyDoc">
                  <h5>
                    Investigation Reports (Lab) and Investigation Breakup bill
                    along with Dr. Prescription
                  </h5>
                  <Box className="fileUpload fileUpload1">
                    <input
                      type="file"
                      ref={copyOfInvestigationReportCash}
                      onChange={(e) =>
                        handleFileChange("copyOfInvestigationReport")
                      }
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfInvestigationReport")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.cashLess.copyOfInvestigationReport
                        .docFile?.name
                        ? inputFields.HealthIns.cashLess
                            .copyOfInvestigationReport.docFile?.name
                        : "Browse to upload doc"}
                    </Button>
                    {inputFields.HealthIns.cashLess.copyOfInvestigationReport.warning ? <span className="error">Select Investigation Report</span> : ""}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid
               xs={12}
               sm={12}
               md={6}
               lg={6}
               sx={{ display: "flex", alignItems: "center" }}
              >
                <div className="healthPolicyDoc">
                  <h5>Pharmacy Bill Breakup along with Dr. Prescription</h5>
                  <Box className="fileUpload fileUpload1">
                    <input
                      type="file"
                      ref={copyOfPharmacyBillCash}
                      onChange={(e) => handleFileChange("copyOfPharmacyBill")}
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfPharmacyBill")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.cashLess.copyOfPharmacyBill.docFile
                        ?.name
                        ? inputFields.HealthIns.cashLess.copyOfPharmacyBill
                            .docFile?.name
                        : "Browse to upload doc"}
                    </Button>
                    {inputFields.HealthIns.cashLess.copyOfPharmacyBill.warning ? <span className="error">Select Pharmacy Bill</span> : ""}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}
               sm={12}
               md={6}
               lg={6}
               sx={{ display: "flex", alignItems: "center" }}>
                <div className="healthPolicyDoc">
                  <h5>Case sheet if available</h5>
                  <Box className="fileUpload fileUpload1">
                    <input
                      type="file"
                      ref={copyOfCaseSheetCash}
                      onChange={(e) => handleFileChange("copyOfCaseSheet")}
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfCaseSheet")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.cashLess.copyOfCaseSheet.docFile
                        ?.name
                        ? inputFields.HealthIns.cashLess.copyOfCaseSheet.docFile
                            ?.name
                        : "Browse to upload doc"}
                    </Button>
                    {inputFields.HealthIns.cashLess.copyOfCaseSheet.warning ? <span className="error">Select Case Sheet</span> : ""}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"What do you expect us to do?"}
                  value={inputFields.HealthIns.cashLess.whatDoYouExpect.value}
                  attrName={["HealthIns", "cashLess", "whatDoYouExpect"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.cashLess.whatDoYouExpect.warning
                  }
                  error_message=" Enter What Do You Expect Us To Do?"
                />
              </Grid>
            </Grid>
          </TabPanel>
          {/* Reimbursement */}
          <TabPanel
            className="tabPanel"
            value="Reimbursement"
            sx={{ display: "flex", gap: "24px", flexDirection: "column" }}>
            <Grid container spacing={3}>
              <Grid xs={12} lg={6}>
                <h5 className="healthHeading mb-3">Claim Status</h5>
                <TabList
                  className="tabListButtons"
                  onChange={(e, a: "Pending" | "Rejected") => {
                    setInputFields({
                      ...inputFields,
                      HealthIns: {
                        ...inputFields.HealthIns,
                        reimbursement: {
                          ...inputFields.HealthIns.reimbursement,
                          claimStatus: a,
                        },
                      },
                    });
                  }}>
                  <Tab
                    className={
                      inputFields.HealthIns.reimbursement.claimStatus ===
                      "Pending"
                        ? "active"
                        : "inActive"
                    }
                    label="Pending"
                    value="Pending"
                  />
                   <Tab
                    className={
                      inputFields.HealthIns.reimbursement.claimStatus ===
                      "Rejected"
                        ? "active"
                        : "inActive"
                    }
                    label="Rejected"
                    value="Rejected"
                  />
                </TabList>
              </Grid>
              <Grid xs={12} lg={6}>
                <h5 className="healthHeading mb-3">Customer Type</h5>
                <div className="bimaType">
                  <CustomButton
                    text_name={"Bimastreet"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.reimbursement.customerType ===
                      "Bimastreet"
                        ? "yes"
                        : "nope"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          reimbursement: {
                            ...inputFields.HealthIns.reimbursement,
                            customerType: "Bimastreet",
                          },
                        },
                      });
                    }}
                  />
                  <CustomButton
                    text_name={"Other"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.reimbursement.customerType ===
                      "Bimastreet"
                        ? "nope"
                        : "yes"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          reimbursement: {
                            ...inputFields.HealthIns.reimbursement,
                            customerType: "Other",
                          },
                        },
                      });
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
              <Grid xs={6}>
                <TabList
                  className="tabListButtons"
                  onChange={(e, a: "Pending" | "Rejected") => {
                    setInputFields({
                      ...inputFields,
                      HealthIns: {
                        ...inputFields.HealthIns,
                        reimbursement: {
                          ...inputFields.HealthIns.reimbursement,
                          claimStatus: a,
                        },
                      },
                    });
                  }}
                >
                  <Tab
                    className={
                      inputFields.HealthIns.reimbursement.claimStatus ===
                      "Rejected"
                        ? "active"
                        : "inActive"
                    }
                    label="Rejected"
                    value="Rejected"
                  />
                  <Tab
                    className={
                      inputFields.HealthIns.reimbursement.claimStatus ===
                      "Pending"
                        ? "active"
                        : "inActive"
                    }
                    label="Pending"
                    value="Pending"
                  />
                </TabList>
              </Grid>
              <Grid xs={6}>
                <div className="bimaType">
                  <CustomButton
                    text_name={"Bimastreet"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.reimbursement.customerType ===
                      "Bimastreet"
                        ? "yes"
                        : "nope"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          reimbursement: {
                            ...inputFields.HealthIns.reimbursement,
                            customerType: "Bimastreet",
                          },
                        },
                      });
                    }}
                  />
                  <CustomButton
                    text_name={"Other"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.reimbursement.customerType ===
                      "Bimastreet"
                        ? "nope"
                        : "yes"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          reimbursement: {
                            ...inputFields.HealthIns.reimbursement,
                            customerType: "Other",
                          },
                        },
                      });
                    }}
                  />
                </div>
              </Grid>
            </Grid> */}
            <Grid container spacing={3}>
              <Grid xs={12} sm={6}>
                <>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid xs={12}>
                      <div className="healthPolicyDoc">
                        <h5>Policy Document</h5>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid 
                     xs={12}
                     sm={12}
                     md={6}
                     lg={6}
                     sx={{ display: "flex", alignItems: "center" }}
                    >
                      <div className="healthPolicyDoc">
                        <Box className="fileUpload fileUpload1">
                          <input
                            type="file"
                            ref={copyOfPolicyDocRe}
                            onChange={(e) =>
                              handleFileChange("copyOfPolicyDoc")
                            }
                            style={{ display: "none" }}
                            
                          />
                          <Button
                            className="cloudButton"
                            variant="contained"
                            onClick={() => selectFile("copyOfPolicyDoc")}>
                            <CloudUploadIcon />
                            {inputFields.HealthIns.reimbursement.copyOfPolicyDoc
                              .docFile?.name
                              ? inputFields.HealthIns.reimbursement
                                  .copyOfPolicyDoc.docFile?.name
                              : "Browse to upload policy doc"}
                          </Button>
                          {inputFields.HealthIns.reimbursement.copyOfPolicyDoc.warning ? <span className="error">Select Policy Document</span> : ""}
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </>
              </Grid>
              <Grid xs={12} sm={6}>
                <>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid xs={12}>
                      <div className="healthPolicyDoc">
                        <h5>Health Card</h5>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid 
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    sx={{ display: "flex", alignItems: "center" }}
                    >
                      <div className="healthPolicyDoc">
                        <Box className="fileUpload fileUpload1">
                          <input
                            type="file"
                            ref={copyOfHealthCardRe}
                            onChange={(e) =>
                              handleFileChange("copyOfHealthCard")
                            }
                            style={{ display: "none" }}
                            
                          />
                          <Button
                            className="cloudButton"
                            variant="contained"
                            onClick={() => selectFile("copyOfHealthCard")}>
                            <CloudUploadIcon />
                            {inputFields.HealthIns.reimbursement
                              .copyOfHealthCard.docFile?.name
                              ? inputFields.HealthIns.reimbursement
                                  .copyOfHealthCard.docFile?.name
                              : "Browse to upload Health Card"}
                          </Button>
                          {inputFields.HealthIns.reimbursement.copyOfHealthCard.warning ? <span className="error">Select Health Card</span> : ""}
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <DatePicker
                  className="px-0"
                  title={"Date of admission"}
                  value={
                    inputFields.HealthIns.reimbursement.dateOfAdmission.value
                  }
                  attrName={["HealthIns", "reimbursement", "dateOfAdmission"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.reimbursement.dateOfAdmission.warning
                  }
                  error_message={" Enter Date Of Admission"}
                  date_validation_type="DAYS"
                  min_date={60}
                  max_date={0}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <DatePicker
                  className="px-0"
                  title={"Date of discharge"}
                  value={
                    inputFields.HealthIns.reimbursement.dateOfDiscahrge.value
                  }
                  attrName={["HealthIns", "reimbursement", "dateOfDiscahrge"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.reimbursement.dateOfDiscahrge.warning
                  }
                  error_message={" Enter Date Of Discharge"}
                  date_validation_type="DAYS"
                  min_date={60}
                  max_date={0}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {inputFields.HealthIns.reimbursement.claimStatus ===
              "Rejected" ? (
                <>
                  <Grid xs={12} sm={12} md={6} lg={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Reason for rejection"}
                      value={
                        inputFields.HealthIns.reimbursement.reasonForRejection
                          .value
                      }
                      attrName={[
                        "HealthIns",
                        "reimbursement",
                        "reasonForRejection",
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={
                        inputFields.HealthIns.reimbursement.reasonForRejection
                          .warning
                      }
                      error_message={" Enter Reason For Rejection"}
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    sx={{ display: "flex", alignItems: "center" }}>
                    <Box className="fileUpload file1" padding="0px">
                      <input
                        type="file"
                        ref={copyOfRejectionLetterRe}
                        onChange={(e) =>
                          handleFileChange("copyOfRejectionLetter")
                        }
                        style={{ display: "none" }}
                        
                      />
                      <Button
                        className="cloudButton"
                        variant="contained"
                        onClick={() => selectFile("copyOfRejectionLetter")}>
                        <CloudUploadIcon />
                        {inputFields.HealthIns.reimbursement
                          .copyOfRejectionLetter.docFile?.name
                          ? inputFields.HealthIns.reimbursement
                              .copyOfRejectionLetter.docFile?.name
                          : "Browse to upload rejection letter"}
                      </Button>
                      {inputFields.HealthIns.reimbursement.copyOfRejectionLetter.warning ? <span className="error">Select Rejection Letter</span> : ""}
                    </Box>
                  </Grid>
                </>
              ) : (
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Reason for Pending"}
                    value={
                      inputFields.HealthIns.reimbursement.reasonForPending.value
                    }
                    attrName={[
                      "HealthIns",
                      "reimbursement",
                      "reasonForPending",
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={
                      inputFields.HealthIns.reimbursement.reasonForPending
                        .warning
                    }
                    error_message={" Enter Reason For Pending"}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={
                    "Discharge Summary Original (With date - time of admission and discharge)"
                  }
                  value={
                    inputFields.HealthIns.reimbursement.dischargeSummary.value
                  }
                  attrName={["HealthIns", "reimbursement", "dischargeSummary"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.reimbursement.dischargeSummary.warning
                  }
                  error_message=" Enter Discharge Summary"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"Final Bill Breakup"}
                  value={
                    inputFields.HealthIns.reimbursement.finalBillBreakup.value
                  }
                  attrName={["HealthIns", "reimbursement", "finalBillBreakup"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.reimbursement.finalBillBreakup.warning
                  }
                  error_message=" Enter Final Bill Breakup"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <div className="claimPolicyDoc">
                  <h5>Cash Paid Receipt: </h5>
                  <Box className="fileUpload file1" padding="0px">
                    <input
                      type="file"
                      ref={copyOfCashReceiptRe}
                      onChange={(e) => handleFileChange("copyOfCashReceipt")}
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfCashReceipt")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.reimbursement.copyOfCashReceipt
                        .docFile?.name
                        ? inputFields.HealthIns.reimbursement.copyOfCashReceipt
                            .docFile?.name
                        : "Browse to upload cash paid receipt"}
                    </Button>
                    {inputFields.HealthIns.reimbursement.copyOfCashReceipt.warning ? <span className="error">Select Cash Receipt</span> : ""}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid 
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ display: "flex", alignItems: "center" }}
              >
                <div className="healthPolicyDoc">
                  <h5>
                    Investigation Reports (Lab) and Investigation Breakup bill
                    along with Dr. Prescription
                  </h5>
                  <Box className="fileUpload fileUpload1">
                    <input
                      type="file"
                      ref={copyOfInvestigationReportRe}
                      onChange={(e) =>
                        handleFileChange("copyOfInvestigationReport")
                      }
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfInvestigationReport")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.reimbursement
                        .copyOfInvestigationReport.docFile?.name
                        ? inputFields.HealthIns.reimbursement
                            .copyOfInvestigationReport.docFile?.name
                        : "Browse to upload doc"}
                    </Button>
                    {inputFields.HealthIns.reimbursement.copyOfInvestigationReport.warning ? <span className="error">Select Investigation Report</span> : ""}

                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid 
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ display: "flex", alignItems: "center" }}
              >
                <div className="healthPolicyDoc">
                  <h5>Pharmacy Bill Breakup along with Dr. Prescription</h5>
                  <Box className="fileUpload fileUpload1">
                    <input
                      type="file"
                      ref={copyOfPharmacyBillRe}
                      onChange={(e) => handleFileChange("copyOfPharmacyBill")}
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfPharmacyBill")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.reimbursement.copyOfPharmacyBill
                        .docFile?.name
                        ? inputFields.HealthIns.reimbursement.copyOfPharmacyBill
                            .docFile?.name
                        : "Browse to upload doc"}
                    </Button>
                    {inputFields.HealthIns.reimbursement.copyOfPharmacyBill.warning ? <span className="error">Select Pharmacy Bill</span> : ""}

                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}
               sm={12}
               md={6}
               lg={6}
               sx={{ display: "flex", alignItems: "center" }}>
                <div className="healthPolicyDoc">
                  <h5>Case sheet if available</h5>
                  <Box className="fileUpload fileUpload1">
                    <input
                      type="file"
                      ref={copyOfCaseSheetRe}
                      onChange={(e) => handleFileChange("copyOfCaseSheet")}
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfCaseSheet")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.reimbursement.copyOfCaseSheet
                        .docFile?.name
                        ? inputFields.HealthIns.reimbursement.copyOfCaseSheet
                            .docFile?.name
                        : "Browse to upload doc"}
                    </Button>
                    {inputFields.HealthIns.reimbursement.copyOfCaseSheet.warning ? <span className="error">Select Case Sheet</span> : ""}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"What do you expect us to do?"}
                  value={
                    inputFields.HealthIns.reimbursement.whatDoYouExpect.value
                  }
                  attrName={["HealthIns", "reimbursement", "whatDoYouExpect"]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.reimbursement.whatDoYouExpect.warning
                  }
                  error_message="Enter What Do You Expect Us To Do?"
                />
              </Grid>
            </Grid>
          </TabPanel>
          {/* Claim partially settled */}
          <TabPanel
            className="tabPanel ownDamage"
            value="Claim Partially Settled"
            sx={{ display: "flex", gap: "24px", flexDirection: "column" }}>
            <Grid container spacing={3}>
              <Grid xs={12} sm={6}>
                <h5 className="healthHeading mb-3">Claim Status</h5>
                {/* <CustomButton
                  text_name={"Pending"}
                  class_name="greenMdBtn yes"
                /> */}
                 <TabList
                  className="tabListButtons"
                  onChange={(e, a: "Pending" | "Rejected") => {
                    setInputFields({
                      ...inputFields,
                      HealthIns: {
                        ...inputFields.HealthIns,
                        ClaimPartiallySettled: {
                          ...inputFields.HealthIns.ClaimPartiallySettled,
                          claimStatus: a,
                        },
                      },
                    });
                  }}>
                  <Tab
                    className={
                      inputFields.HealthIns.ClaimPartiallySettled.claimStatus ===
                      "Pending"
                        ? "active"
                        : "inActive"
                    }
                    label="Pending"
                    value="Pending"
                  />
                   <Tab
                    className={
                      inputFields.HealthIns.ClaimPartiallySettled.claimStatus ===
                      "Rejected"
                        ? "active"
                        : "inActive"
                    }
                    label="Rejected"
                    value="Rejected"
                  />
                </TabList>
              </Grid>
              <Grid xs={12} sm={6}>
                <h5 className="healthHeading mb-3">Customer Type</h5>
                <div className="bimaType">
                  <CustomButton
                    text_name={"Bimastreet"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.ClaimPartiallySettled
                        .customerType === "Bimastreet"
                        ? "yes"
                        : "nope"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          ClaimPartiallySettled: {
                            ...inputFields.HealthIns.ClaimPartiallySettled,
                            customerType: "Bimastreet",
                          },
                        },
                      });
                    }}
                  />
                  <CustomButton
                    text_name={"Other"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.ClaimPartiallySettled
                        .customerType === "Bimastreet"
                        ? "nope"
                        : "yes"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          ClaimPartiallySettled: {
                            ...inputFields.HealthIns.ClaimPartiallySettled,
                            customerType: "Other",
                          },
                        },
                      });
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
              <Grid xs={6}>
                <CustomButton
                  text_name={"Pending"}
                  class_name="greenMdBtn yes"
                />
              </Grid>
              <Grid xs={6}>
                <div className="bimaType">
                  <CustomButton
                    text_name={"Bimastreet"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.ClaimPartiallySettled
                        .customerType === "Bimastreet"
                        ? "yes"
                        : "nope"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          ClaimPartiallySettled: {
                            ...inputFields.HealthIns.ClaimPartiallySettled,
                            customerType: "Bimastreet",
                          },
                        },
                      });
                    }}
                  />
                  <CustomButton
                    text_name={"Other"}
                    class_name={`greenMdBtn ${
                      inputFields.HealthIns.ClaimPartiallySettled
                        .customerType === "Bimastreet"
                        ? "nope"
                        : "yes"
                    }`}
                    onClickFunction={() => {
                      setInputFields({
                        ...inputFields,
                        HealthIns: {
                          ...inputFields.HealthIns,
                          ClaimPartiallySettled: {
                            ...inputFields.HealthIns.ClaimPartiallySettled,
                            customerType: "Other",
                          },
                        },
                      });
                    }}
                  />
                </div>
              </Grid>
            </Grid> */}
            <Grid container spacing={3}>
              <Grid xs={12} sm={6}>
                <>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid 
                     xs={12}
                     sm={12}
                     md={6}
                     lg={6}
                     sx={{ display: "flex", alignItems: "center" }}
                    >
                      <div className="healthPolicyDoc">
                        <h5 className="docHead">Policy Document</h5>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid 
                     xs={12}
                     sm={12}
                     md={6}
                     lg={6}
                     sx={{ display: "flex", alignItems: "center" }}
                    >
                      <div className="healthPolicyDoc">
                        <Box className="fileUpload fileUpload1">
                          <input
                            type="file"
                            ref={copyOfPolicyDocClaim}
                            onChange={(e) =>
                              handleFileChange("copyOfPolicyDoc")
                            }
                            style={{ display: "none" }}
                            
                          />
                          <Button
                            className="cloudButton"
                            variant="contained"
                            onClick={() => selectFile("copyOfPolicyDoc")}>
                            <CloudUploadIcon />
                            {inputFields.HealthIns.ClaimPartiallySettled
                              .copyOfPolicyDoc.docFile?.name
                              ? inputFields.HealthIns.ClaimPartiallySettled
                                  .copyOfPolicyDoc.docFile?.name
                              : "Browse to upload policy doc"}
                          </Button>
                          {inputFields.HealthIns.ClaimPartiallySettled.copyOfPolicyDoc.warning ? <span className="error">Select Policy Document</span> : ""}
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </>
              </Grid>

              <Grid xs={12} sm={6}>
                <>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid xs={12}>
                      <div className="healthPolicyDoc">
                        <h5 className="docHead">Health Card</h5>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} columnSpacing={0}>
                    <Grid 
                     xs={12}
                     sm={12}
                     md={6}
                     lg={6}
                     sx={{ display: "flex", alignItems: "center" }}
                    >
                      <div className="healthPolicyDoc">
                        <Box className="fileUpload fileUpload1">
                          <input
                            type="file"
                            ref={copyOfHealthCardClaim}
                            onChange={(e) =>
                              handleFileChange("copyOfHealthCard")
                            }
                            style={{ display: "none" }}
                            
                          />
                          <Button
                            className="cloudButton"
                            variant="contained"
                            onClick={() => selectFile("copyOfHealthCard")}>
                            <CloudUploadIcon />
                            {inputFields.HealthIns.ClaimPartiallySettled
                              .copyOfHealthCard.docFile?.name
                              ? inputFields.HealthIns.ClaimPartiallySettled
                                  .copyOfHealthCard.docFile?.name
                              : "Browse to upload Health Card"}
                          </Button>
                          {inputFields.HealthIns.ClaimPartiallySettled.copyOfHealthCard.warning ? <span className="error">Select Health Card</span> : ""}
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <DatePicker
                  className="px-0"
                  title={"Date of admission"}
                  value={
                    inputFields.HealthIns.ClaimPartiallySettled.dateOfAdmission
                      .value
                  }
                  attrName={[
                    "HealthIns",
                    "ClaimPartiallySettled",
                    "dateOfAdmission",
                  ]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.ClaimPartiallySettled.dateOfAdmission
                      .warning
                  }
                  error_message={" Enter Date Of Admission"}
                  date_validation_type="DAYS"
                  min_date={60}
                  max_date={0}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={4}>
                <DatePicker
                  className="px-0"
                  title={"Date of discharge"}
                  value={
                    inputFields.HealthIns.ClaimPartiallySettled.dateOfDiscahrge
                      .value
                  }
                  attrName={[
                    "HealthIns",
                    "ClaimPartiallySettled",
                    "dateOfDiscahrge",
                  ]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.ClaimPartiallySettled.dateOfDiscahrge
                      .warning
                  }
                  error_message={" Enter Date Of Discharge"}
                  date_validation_type="DAYS"
                  min_date={60}
                  max_date={0}
                />
              </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Reason for Pending"}
                  value={
                    inputFields.HealthIns.ClaimPartiallySettled.reasonForPending
                      .value
                  }
                  attrName={[
                    "HealthIns",
                    "ClaimPartiallySettled",
                    "reasonForPending",
                  ]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.ClaimPartiallySettled.reasonForPending
                      .warning
                  }
                  error_message={" Enter Reason For Pending"}
                />
              </Grid>
            </Grid> */}
            <Grid container spacing={3}>
              {inputFields.HealthIns.ClaimPartiallySettled.claimStatus ===
              "Rejected" ? (
                <>
                  <Grid xs={12} sm={12} md={6} lg={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Reason for rejection"}
                      value={
                        inputFields.HealthIns.ClaimPartiallySettled.reasonForRejection
                          .value
                      }
                      attrName={[
                        "HealthIns",
                        "ClaimPartiallySettled",
                        "reasonForRejection",
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={
                        inputFields.HealthIns.ClaimPartiallySettled.reasonForRejection
                          .warning
                      }
                      error_message={" Enter Reason For Rejection"}
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    sx={{ display: "flex", alignItems: "center" }}>
                    <Box className="fileUpload file1" padding="0px">
                      <input
                        type="file"
                        ref={copyOfRejectionLetterPartially}
                        onChange={(e) =>
                          handleFileChange("copyOfRejectionLetter")
                        }
                        style={{ display: "none" }}
                        
                      />
                      <Button
                        className="cloudButton"
                        variant="contained"
                        onClick={() => selectFile("copyOfRejectionLetter")}>
                        <CloudUploadIcon />
                        {inputFields.HealthIns.ClaimPartiallySettled
                          .copyOfRejectionLetter.docFile?.name
                          ? inputFields.HealthIns.ClaimPartiallySettled
                              .copyOfRejectionLetter.docFile?.name
                          : "Browse to upload rejection letter"}
                      </Button>
                    {inputFields.HealthIns.ClaimPartiallySettled.copyOfRejectionLetter.warning ? <span className="error">Select Rejection Letter</span> : ""}
                    </Box>
                  </Grid>
                </>
              ) : (
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Reason for Pending"}
                    value={
                      inputFields.HealthIns.ClaimPartiallySettled.reasonForPending.value
                    }
                    attrName={[
                      "HealthIns",
                      "ClaimPartiallySettled",
                      "reasonForPending",
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={
                      inputFields.HealthIns.ClaimPartiallySettled.reasonForPending
                        .warning
                    }
                    error_message={" Enter Reason For Pending"}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={
                    "Discharge Summary Original (With date - time of admission and discharge)"
                  }
                  value={
                    inputFields.HealthIns.ClaimPartiallySettled.dischargeSummary
                      .value
                  }
                  attrName={[
                    "HealthIns",
                    "ClaimPartiallySettled",
                    "dischargeSummary",
                  ]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.ClaimPartiallySettled.dischargeSummary
                      .warning
                  }
                  error_message=" Enter Discharge Summary"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"Final Bill Breakup"}
                  value={
                    inputFields.HealthIns.ClaimPartiallySettled.finalBillBreakup
                      .value
                  }
                  attrName={[
                    "HealthIns",
                    "ClaimPartiallySettled",
                    "finalBillBreakup",
                  ]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.ClaimPartiallySettled.finalBillBreakup
                      .warning
                  }
                  error_message=" Enter Final Bill Breakup"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <div className="claimPolicyDoc">
                  <h5>Cash Paid Receipt: </h5>
                  <Box className="fileUpload file1" padding="0px">
                    <input
                      type="file"
                      ref={copyOfCashReceiptClaim}
                      onChange={(e) => handleFileChange("copyOfCashReceipt")}
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfCashReceipt")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.ClaimPartiallySettled
                        .copyOfCashReceipt.docFile?.name
                        ? inputFields.HealthIns.ClaimPartiallySettled
                            .copyOfCashReceipt.docFile?.name
                        : "Browse to upload cash paid receipt"}
                    </Button>
                    {inputFields.HealthIns.ClaimPartiallySettled.copyOfCashReceipt.warning ? <span className="error">Select Cash Receipt</span> : ""}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ display: "flex", alignItems: "center" }}
              >
                <div className="healthPolicyDoc">
                  <h5>
                    Investigation Reports (Lab) and Investigation Breakup bill
                    along with Dr. Prescription
                  </h5>
                  <Box className="fileUpload fileUpload1">
                    <input
                      type="file"
                      ref={copyOfInvestigationReportClaim}
                      onChange={(e) =>
                        handleFileChange("copyOfInvestigationReport")
                      }
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfInvestigationReport")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.ClaimPartiallySettled
                        .copyOfInvestigationReport.docFile?.name
                        ? inputFields.HealthIns.ClaimPartiallySettled
                            .copyOfInvestigationReport.docFile?.name
                        : "Browse to upload doc"}
                    </Button>
                    {inputFields.HealthIns.ClaimPartiallySettled.copyOfInvestigationReport.warning ? <span className="error">Select Investigation Report</span> : ""}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid 
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ display: "flex", alignItems: "center" }}
              >
                <div className="healthPolicyDoc">
                  <h5>Pharmacy Bill Breakup along with Dr. Prescription</h5>
                  <Box className="fileUpload fileUpload1">
                    <input
                      type="file"
                      ref={copyOfPharmacyBillClaim}
                      onChange={(e) => handleFileChange("copyOfPharmacyBill")}
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfPharmacyBill")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.ClaimPartiallySettled
                        .copyOfPharmacyBill.docFile?.name
                        ? inputFields.HealthIns.ClaimPartiallySettled
                            .copyOfPharmacyBill.docFile?.name
                        : "Browse to upload doc"}
                    </Button>
                    {inputFields.HealthIns.ClaimPartiallySettled.copyOfPharmacyBill.warning ? <span className="error">Select Pharmacy Bill</span> : ""}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}
               sm={12}
               md={6}
               lg={6}
               sx={{ display: "flex", alignItems: "center" }}>
                <div className="healthPolicyDoc">
                  <h5>Case sheet if available</h5>
                  <Box className="fileUpload fileUpload1">
                    <input
                      type="file"
                      ref={copyOfCaseSheetClaim}
                      onChange={(e) => handleFileChange("copyOfCaseSheet")}
                      style={{ display: "none" }}
                      
                    />
                    <Button
                      className="cloudButton"
                      variant="contained"
                      onClick={() => selectFile("copyOfCaseSheet")}>
                      <CloudUploadIcon />
                      {inputFields.HealthIns.ClaimPartiallySettled
                        .copyOfCaseSheet.docFile?.name
                        ? inputFields.HealthIns.ClaimPartiallySettled
                            .copyOfCaseSheet.docFile?.name
                        : "Browse to upload doc"}
                    </Button>
                    {inputFields.HealthIns.ClaimPartiallySettled.copyOfCaseSheet.warning ? <span className="error">Select Case Sheet</span> : ""}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <TextArea
                  class_name="inputField"
                  title={"What do you expect us to do?"}
                  value={
                    inputFields.HealthIns.ClaimPartiallySettled.whatDoYouExpect
                      .value
                  }
                  attrName={[
                    "HealthIns",
                    "ClaimPartiallySettled",
                    "whatDoYouExpect",
                  ]}
                  value_update={fieldsUpdateState}
                  warn_status={
                    inputFields.HealthIns.ClaimPartiallySettled.whatDoYouExpect
                      .warning
                  }
                  error_message="Enter What Do You Expect Us To Do?"
                />
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    </>
  );
};

export default HealthClaim;

import React, { useRef, useState } from "react";
import MNavbar from "../../../../Component/Mobile/MNavbar/MNavbar";
import MFooter from "../../../../Component/Mobile/MFooter/MFooter";
import Grid from "@mui/material/Unstable_Grid2";
import "./MCareer.scss";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import Buttons from "../../../../Component/Common/CustomButton/CustomButton";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "@mui/material";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";

export default function MCareers({
  validate_Form,
  complaintFields,
  fieldsUpdateState,
  applyfor_data,
  setcomplaintFields,
  handleFileSelect,
  handleFileChange,
  selectedFileName,
  fileInputRef,
}: {
  validate_Form: Function;
  complaintFields: any;
  fieldsUpdateState: any;
  applyfor_data: any;
  setcomplaintFields: any;
  handleFileSelect: any;
  handleFileChange: any;
  selectedFileName: any;
  fileInputRef: any;
}) {
  return (
    <>
      <HelmetComponent
        title="Get Car, Bike, Health & Life Insurance with Bimastreet"
        description="Protect your future with top-rated bike, car, health, and term insurance. Discover affordable, comprehensive plans with free claim assistance."
        showCanonicalTag={true}
      />
      <MNavbar />
      <div className="career_wrapper">
        <Grid container spacing={3}>
          <Grid xs={12} className="main_heading">
            <h5>
              Careers
              <span>
                Seeking free spirits and visionaries who dare to think outside
                the box.
              </span>
            </h5>
          </Grid>
          <Grid xs={12}>
            <img
              src="./images/career_mobile_img.svg"
              alt=""
              className="career_img"
            />
          </Grid>
          <Grid xs={12}>
            <div className="form_details">
              <h6>
                Want to connect with us?
                <span>Just share your information.</span>
              </h6>
              <div className="file_upload mb-6">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <Button
                  variant="contained"
                  onClick={handleFileSelect} // Call this function to trigger file input click
                >
                  <CloudUploadIcon />
                  Browse to upload your CV
                </Button>
                {selectedFileName && <p> {selectedFileName}</p>}
              </div>
              <Grid container rowSpacing={3}>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    validation_type="NAME"
                    value={complaintFields.fullname?.value}
                    attrName={[
                      "fullname",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.fullname?.warning}
                    error_message={
                      isEmpty(complaintFields.fullname?.value)
                        ? "Enter Full Name"
                        : "Enter Valid Full Name "
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile"}
                    value={complaintFields.mobile?.value}
                    attrName={[
                      "mobile",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.mobile?.warning}
                    error_message={
                      isEmpty(complaintFields.mobile?.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid mobile number"
                    }
                    max_length={10}
                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Email"}
                    value={complaintFields.email?.value}
                    attrName={[
                      "email",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.email?.warning}
                    error_message={
                      isEmpty(complaintFields.email?.value)
                        ? "Enter Email"
                        : "Enter Valid Email"
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Want to apply for"
                    value={complaintFields.applyfor?.value}
                    attrName={[
                      "applyfor",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    data={applyfor_data}
                    warn_status={complaintFields.applyfor?.warning}
                  />
                </Grid>
                <Grid xs={12} className="px-0 text-center">
                  <div className="ctaBtn">
                    <Buttons
                      onClickFunction={validate_Form}
                      class_name="greenLgBtn"
                      text_name="Submit Details"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <MFooter />
    </>
  );
}

export enum COMMON_ROUTES {
  HOME = "/",
  POS = "/pos",
  PRIVACY_POLICY = "/privacy-policy",
  TERM_AND_CONDTION = "/terms-and-conditions",
  NEED_HELP = "/need-help",
  DISCLAIMER = "/disclaimer",
  REFUND_AND_CANCELLATION = "/refund-and-cancellation",
  ABOUT_US = "/about-us",
  CONTACT_US = "/contact-us",
  FREE_CLAIM_ASSISTANCE = "/free-claim-assistance",
  CARRIER = "/carriers",
  MCONTACT_US = "/mcontact-us",
  MCOMPLAINT = "/mcomplaint",
  MCAREER = "/mcareer",
  PIR = "/pir",
  PIR_REPORT = "/pir-report",

  PRODUCT_HEALTH = "/health-insurance",
  PRODUCT_CAR = "/car-insurance",
  PRODUCT_TW = "/two-wheeler-insurance",
  PRODUCT_TERM = "/term-insurance",
  CLAIMS = "/claims",
  SITEMAP = "/sitemap",

  PAYMENT_CONFIRMATION = "/payment-confirmation",

  HEALTH_FORM = "/form/container/health/form",
  CAR_FORM = "/form/container/car/form",
  TW_FORM = "/form/container/tw/form",
  HOME_FORM = "/form/container/home/form",
  TERM_FORM = "/form/container/term/form",
  TRAVEL_FORM = "/form/container/travel/form",

  HEALTH_QUOTE = "/quote/container/health",
  HOME_QUOTE = "/quote/container/home",
  CAR_QUOTE = "/quote/container/car",
  TW_QUOTE = "/quote/container/tw",
  TERM_QUOTE = "/quote/container/term",
  TRAVEL_QUOTE = "/quote/container/travel",
}

export enum HEALTH_ROUTE {
  QOUTE_PAGE = "/quote/health-quote",
  PROPOSAL_PAGE = "/health-insurance",
  KYC_DETAILS = "health-kyc-details",
  ADDON_DETAILS = "health-addon-details",
  ADULT_ONE_DETAILS = "health-adult-one-details",
  INSURED_MEMBER = "health-adult-one-details_members",
  NOMINEE_DETAILS = "health-noninee-details",
  ADULT_TWO_DETAILS = "health-adult-two-details",
  CHILD_ONE_DETAILS = "health-child-one-details",
  CHILD_TWO_DETAILS = "health-child-two-details",
  CHILD_THREE_DETAILS = "health-child-three-details",
  CHILD_FOUR_DETAILS = "health-child-four-details",
  ADDRESS_DETAILS = "health-address-details",
  MEDICAL_DETAILS = "health-medical-details",
  PREVIEW_DETAILS = "health-preview-details",
  PAYMENT_CIS = "cis-pdf",
  BANK_DETAILS = "bank-details",
}

export enum CAR_ROUTES {
  QUOTE = "/quote/car-insurance/car-quote",
  PROPOSAL_PAGE = "/car-insurance",
  OWNER_DETAILS = "owner-details",
  VEHICLE_DETAILS = "vehicle-details",
  NOMINEE_DETAILS = "nominee-details",
  ADDRESS_DETAILS = "address-details",
  PREVIEW_DEATILS = "preview-details",
  INSPECTION_CONFIRMATION = "/inspection-confirmation",
}

export enum TW_ROUTES {
  QUOTE = "/quote/two-wheeler-insurance/two-wheeler-quote",
  PROPOSAL_PAGE = "/two-wheeler-insurance",
  OWNER_DETAILS = "owner-details",
  VEHICLE_DETAILS = "vehicle-details",
  NOMINEE_DETAILS = "nominee-details",
  ADDRESS_DETAILS = "address-details",
  PREVIEW_DEATILS = "preview-details",
  POLICY_CONFIRMATION = "/tw-insurance/payment-response",
}

import { STRAPI_DOMAIN } from "../../../../../URLCollection/Domain";
import { useAppSelector } from "../../../../../Store/hooks";
import { useEffect, useState } from "react";
import { TKeyManagementPersonal } from "../../../../../Type/TCMSSlice";

function KeyManagTool() {
  const { key_management_personal } = useAppSelector((state) => state.CMS);
  const customOrder = [
    "Smt K. Aruna Devi,",
    "Dr. Muralidhara Reddy",
    "Director",
    "Dy. Managing Director",
    "Chief Operating Officer (COO)",
    "General Manager (Life)",
  ];
  const [keyMP, setKeyMP] = useState<TKeyManagementPersonal | null>(null);

  useEffect(() => {
    if (key_management_personal) {
      const sortedData = {
        ...key_management_personal,
        data: {
          ...key_management_personal.data,
          data: [...key_management_personal.data.data].sort((a, b) => {
            const nameA = a.attributes.name;
            const nameB = b.attributes.name;
            return customOrder.indexOf(nameA) - customOrder.indexOf(nameB);
          }),
        },
      };
      setKeyMP(sortedData);
    }
  }, [key_management_personal]);

  return (
    <div className="about-us-management">
      {keyMP &&
        keyMP.data.data.map((data, index) => (
          <div className="mainWrapper">
            <div className="content-wrapper">
              <div className="content">
                <div className="fiii-chief-operating">
                  {data.attributes.name}
                </div>
                <div className="kv-bhaskar-rao-ma-parent">
                  <div className="kv-bhaskar-rao">
                    {data.attributes.designation}
                  </div>
                  <div className="instance-parent">
                    {data.attributes.data.map((sub_data, sub_index) => (
                      <div className="charmcircle-tick-parent">
                        <img
                          className="charmcircle-tick-icon"
                          alt=""
                          src="./images/list-tick-icon.svg"
                        />
                        <div
                          className="no-limit-on"
                          dangerouslySetInnerHTML={{
                            __html: sub_data.desc,
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="photo">
                <img
                  alt=""
                  src={`${STRAPI_DOMAIN}${data.attributes.image.data.attributes.url}`}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default KeyManagTool;
